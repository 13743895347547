import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Button, IconButton, Drawer, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AUTH_MODALS } from 'Constants/modals';
import { logoutUser, setAuthModal, setActiveModal } from 'Store/actions';
import GlobalButton from 'Shared/Button/index';
import Modal from 'Shared/Modal';
import Container from 'Components/Container';
import LoginV2 from 'Pages/Login-v2';
import SignUpV3 from 'Pages/SignUp-v3';
import DropdownMenu from './DropdownMenu';
import DesktopMenuItemButton from './DesktopMenuItemButton';
import MobileMenuItemButton from './MobileMenuItemButton';

import Logo_Lootvault from './assets/Logo_Lootvault.svg';
import Logo from './assets/Logo.svg';
import Cross from './assets/cross.svg';
import User from './assets/user.png';

import { useStyles } from './style';
import './style.css';

const navLinks = [
  // {
  //   label: 'Make NFTs',
  //   href: '/create-collection',
  //   list: [
  //     { label: 'Create Collection', href: '/create-collection' },
  //     { label: 'Create NFT', href: '/mint-nft' },
  //     { label: 'API for Developers', href: '/api-pricing' },
  //   ],
  // },

  // {
  //   type: 'scroll',
  //   label: 'NFT API',
  //   name: 'nft-api',
  //   orgUrl: '/',
  // },
  // {
  //   label: 'On-Chain Website',
  //   href: '/contactDeploy',
  // },

  {
    label: 'Contact Us',
    href: '/contact',
  },

  {
    type: 'popup',
    label: 'NFT News',
    href: 'https://cdn.forms-content.sg-form.com/6b2a841b-171c-11ec-8f8f-dee85ee6357c',
  },

  {
    label: 'NFT Lookup',
    href: '/nft-lookup',
    list: [
      { label: 'Find an NFT', href: '/nft-lookup' },
      { label: 'Email an NFT Owner', href: '/nft-lookup' },
      { label: 'Verify an NFT', href: '/nft-lookup' },
    ],
  },

  // {
  //   label: 'Digital Wallet',
  //   href: '/my-collection',
  // },

  {
    label: 'NFT API',
    href: '/api-pricing',
  },

  // {
  //   type: 'external',
  //   label: 'Docs',
  //   href: 'https://ownerfy.readme.io/',
  //   list: [
  //     {
  //       type: 'external',
  //       label: 'Readme Docs',
  //       href: 'https://ownerfy.readme.io/reference/introduction-to-ownerfy-api',
  //     },
  //     {
  //       type: 'external',
  //       label: 'Postman Docs',
  //       href: 'https://api.ownerfy.com',
  //     },
  //     {
  //       label: 'FAQ',
  //       href: '/faq',
  //     },

  //     {
  //       type: 'external',
  //       label: 'NFT Minting Wizard',
  //       href: 'https://ownerfy.readme.io/reference/nft-wizard-guide',
  //     },

  //     {
  //       type: 'external',
  //       label: 'Make a Claimable NFT',
  //       href: 'https://ownerfy.readme.io/reference/making-nfts-claimable-for-free',
  //     },

  //     {
  //       type: 'external',
  //       label: 'Products and clothes',
  //       href: 'https://ownerfy.readme.io/reference/how-do-make-nfts-for-products-and-clothing',
  //     },

  //     {
  //       type: 'external',
  //       label: 'NFTs for Tickets',
  //       href: 'https://ownerfy.readme.io/reference/how-to-make-and-validate-nft-tickets',
  //     },
  //     {
  //       type: 'external',
  //       label: 'Dedicated smart contracts',
  //       href: 'https://ownerfy.readme.io/reference/how-to-create-smart-contract',
  //     },
  //   ],
  // },

  // {
  //   label: 'Contact',
  //   href: '/contact',
  // },
];

const navLootvaultLinks = [
  {
    label: 'Auctions',
    href: '/lootvault/listings',
  },

  {
    label: 'My Bids',
    href: '/lootvault/bids',
  },
  {
    label: 'Auction FAQ',
    href: '/lootvault/faq',
  },
];

const Navbar = ({ type, backgroundTransparent }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const { authModal } = useSelector(state => state.lootvault);
  const history = useHistory();
  const dispatch = useDispatch();

  const isLootvault = type === 'lootvault';
  const [isMobile, setIsMobile] = useState(false);

  const showAddressModal = () => {
    dispatch(setActiveModal('showAddress'));
  };

  const dropLinks = useMemo(() => {
    const dropdowns = [
      { label: 'Settings', onClick: () => history.push('/settings') },
      { label: 'My Collection', onClick: () => history.push('/my-collection') },
      { label: 'Receive', onClick: showAddressModal },
      { label: 'Mint', onClick: () => history.push('/mint-nft') },
      { label: 'Create Collection', onClick: () => history.push('/create-collection') },
      { label: 'Logout', onClick: () => dispatch(logoutUser({ returnPath: '/' })), 'data-cy': 'logout-button' },
      { label: 'History', onClick: () => history.push('/history') },
      { label: 'FAQ', onClick: () => history.push('/faq') },
    ];

    if (user?.companyId) {
      return [{ label: 'API Dashboard', onClick: () => history.push('/api-usage') }, ...dropdowns];
    } else {
      dropdowns.splice(5, 0, { label: 'API Signup', onClick: () => history.push('/api-signup') });
      return dropdowns;
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    const setResponsiveness = () => {
      if (window.innerWidth < 1092) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const handleScroll = () => {
    return window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const modalTitle = useMemo(() => {
    switch (authModal) {
      case AUTH_MODALS.LOGIN:
        return 'Login';
      case AUTH_MODALS.SIGNUP:
        return 'Sign up';
      default:
        return '';
    }
  }, [authModal]);

  return (
    <main className={classes.headerContainer}>
      <Modal open={!!authModal} heading={modalTitle} onClose={() => dispatch(setAuthModal(null))}>
        {authModal === AUTH_MODALS.LOGIN && <LoginV2 title="Login" />}
        {authModal === AUTH_MODALS.SIGNUP && <SignUpV3 title="Sign Up" />}
      </Modal>

      <div className={classes.linkWrapper}>
        <AppBar
          className={`${classes.header} ${
            backgroundTransparent ? classes.backgroundTransparent : classes.backgroundColor
          }`}
        >
          {isMobile ? (
            <MobileMenu
              mainMenuItems={isLootvault ? navLootvaultLinks : navLinks}
              dropdownItems={dropLinks}
              user={user}
              openLogin={() => dispatch(setAuthModal(AUTH_MODALS.LOGIN))}
            />
          ) : (
            <DesktopMenu
              type={type}
              user={user}
              dropdownItems={dropLinks}
              mainMenuItems={isLootvault ? navLootvaultLinks : navLinks}
              handleScroll={handleScroll}
              openLogin={() => dispatch(setAuthModal(AUTH_MODALS.LOGIN))}
            />
          )}
        </AppBar>
      </div>
    </main>
  );
};

const DesktopMenu = ({ type, user, mainMenuItems, dropdownItems, openLogin }) => {
  const classes = useStyles();
  const [accountMenu, setAccountMenu] = useState(null);

  return (
    <Container className={classes.navContainer}>
      <Toolbar className={classes.toolbar}>
        <a href="/">
          <img src={type === 'lootvault' ? Logo_Lootvault : Logo} alt="logo" className={classes.logo} />
        </a>

        <div>
          {/*{ label, href, type, name, orgUrl }*/}
          {mainMenuItems.map((menuItemProps, index) =>
            menuItemProps?.list ? (
              <DropdownMenu {...menuItemProps} key={index} />
            ) : (
              <DesktopMenuItemButton {...menuItemProps} key={index} />
            ),
          )}
        </div>

        {user ? (
          <>
            <Button
              className={classes.outlinedButton}
              onClick={e => setAccountMenu(e.currentTarget)}
              data-cy="account-popup-button"
            >
              Account
            </Button>

            <Menu
              anchorEl={accountMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={!!accountMenu}
              onClose={() => setAccountMenu(null)}
              classes={{ list: classes.dropdownNav }}
            >
              {dropdownItems.map(({ label, onClick, ...rest }, index) => (
                <MenuItem key={index} onClick={onClick} {...rest}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <GlobalButton btnLabel="Login" data-cy="login-modal-button" handleClick={openLogin} />
        )}
      </Toolbar>
    </Container>
  );
};

const MobileMenu = ({ mainMenuItems, dropdownItems, user, openLogin }) => {
  const MENU_TYPES = { ACCOUNT: 'account', GLOBAL: 'global' };

  const classes = useStyles();
  const [menuType, setMenuType] = useState(null);
  const menuItems = menuType === MENU_TYPES.ACCOUNT ? dropdownItems : mainMenuItems;

  return (
    <Toolbar>
      <div className={classes.mobileViewHeader}>
        <img src={Logo} alt="logo" className={classes.logo} />

        {!menuType && (
          <div>
            {user ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-haspopup="true"
                onClick={() => setMenuType(MENU_TYPES.ACCOUNT)}
              >
                <div style={{ marginRight: '12px', marginTop: '10px' }}>
                  <img src={User} alt="user" />
                </div>
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-haspopup="true"
                data-cy="login-modal-button"
                onClick={openLogin}
              >
                <div style={{ marginRight: '12px', marginTop: '10px' }}>
                  <img src={User} alt="user" />
                </div>
              </IconButton>
            )}

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-haspopup="true"
              onClick={() => setMenuType(MENU_TYPES.GLOBAL)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        )}

        {menuType && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-haspopup="true"
            onClick={() => setMenuType(null)}
          >
            <img src={Cross} alt="Cross" />
          </IconButton>
        )}
      </div>

      <Drawer
        anchor="left"
        open={!!menuType}
        onClose={() => setMenuType(null)}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerContainer}>
          {menuItems.map((menuItem, menuItemIndex) => (
            <MobileMenuItemButton {...menuItem} key={menuItemIndex} />
          ))}
        </div>
      </Drawer>
    </Toolbar>
  );
};

export default Navbar;
