import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { addSmartContract, createContractFromCredit, fetchUserCredits } from 'Services/api';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useCompany from 'Hooks/useCompany';
import Select from 'Shared/SelectField';
import config from 'Config/firebase';
import * as actions from 'Store/actions';
import { createStripeCheckoutSessionForContractCreation, getContractCreationFee } from 'Services/api';
import Input from 'Shared/Input';
import Button from 'Shared/Button';
import Container from 'Components/Container';
import Loading from 'Components/Loading';
import InPageSignupLogin from 'Components/InPageSignupLogin';
import firebaseService from 'Services/firebaseService';

import { useStyles } from './style';

const initialState = [
  {
    id: 1,
    label: 'Property',
  },
  {
    id: 2,
    label: 'Property',
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Collection = ({ user, registerWithFirebase }) => {
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);
  const [blockchain, setBlockchain] = useState([
    { label: 'Polygon', value: 'polygon' },
    { label: 'Ethereum', value: 'ethereum' },
  ]);
  const history = useHistory();
  const [userCredits, setUserCredits] = useState(0);
  const [userSignupValues, setUserSignupValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [contractFee, setContractFee] = useState(false);
  const classess = useStyles();
  const [attributes, setAttributes] = useState(initialState);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { addCompany, getCompany } = useCompany();

  useEffect(() => {
    const _fetchUserCredits = async () => {
      let _userCreditsData = await fetchUserCredits();

      let _userCredits = _userCreditsData?.nftCredits || 0;

      setUserCredits(_userCredits);
    };

    if (user && user.uid) _fetchUserCredits();
  }, [user]);

  const handleSnackBarOpen = () => {
    setSnackBarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const sendToStripeCheckout = async values => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForContractCreation({
        collectionName: values.collectionName,
        blockchain: values.blockchain,
        cancelUrl: window.location.href,
        successUrl: `${window.location.href}-success`,
      });
      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const registerUser = async ({ firstName, lastName, email, password, phone, username }) => {
    try {
      const registrationRequest = await registerWithFirebase({
        firstName: user?.firstName || firstName,
        lastName: user?.lastName || lastName,
        username: user?.username || username,
        email: user?.email || email,
        password: user?.password || password,
        phone: user?.phone || phone,
      });

      if (registrationRequest.payload.includes('auth/email-already-in-use')) {
        return 'auth/email-already-in-use';
      }

      return registrationRequest.payload;
    } catch (err) {
      console.warn('User Registration error: ', err);
      alert(err);
      return;
    }
  };

  const onSubmit = async data => {
    setLoading(true);

    if (!firebaseService.auth.currentUser.emailVerified) {
      alert('Please verify your email address before creating a collection');
      setLoading(false);
      return;
    }

    let _companyId;
    let _isDefaultContract = true;
    let _user = user;
    if (data.collectionName === '') {
      alert('Collection name is required');
      setLoading(false);
      return;
    }

    try {
      if (!_user || !_user.uid) {
        _user = await registerUser(userSignupValues);

        if (_user === 'auth/email-already-in-use') {
          setLoading(false);
          handleSnackBarOpen();
          return;
        }
      }

      if (Number(userCredits) < 10) {
        alert('You need at least 10 credits to create a collection');
        setLoading(false);
        return;
      }

      // All contracts are company based now
      // Everyone must have a default company

      if (!_user.companyId) {
        const companyData = await addCompany({
          name: _user.username,
          userId: _user.uid,
          planId: 'subscription1',
          teamSize: 1,
        });

        _companyId = companyData.companyId;
      } else {
        const companyData = await getCompany();
        _isDefaultContract = !companyData.hasOwnProperty('defaultContract');
        _companyId = companyData.companyId;
      }

      await createContractFromCredit({
        companyId: _companyId,
        minterUserId: _user.uid,
        isDefault: _isDefaultContract,
        name: data.collectionName,
        blockchain: data.selectBlockchain,
      });

      setTimeout(() => {
        history.push('/create-collection-success');
      }, 3000);

      return;

      // await sendToStripeCheckout({
      //   collectionName: data.collectionName,
      //   blockchain: data.selectBlockchain,
      // });
    } catch (err) {
      console.warn(err);
      alert('Error submitting values');
    }
  };

  const addAttributes = () => {
    setAttributes([...attributes, { id: attributes.length + 1, label: 'Property' }]);
  };
  const removeAttributes = id => {
    setAttributes(attributes.filter(attribute => attribute.id !== id));
  };

  const validationSchema = Yup.object().shape({
    collectionName: Yup.string().required('Collection name is required'),
    //selectBlockchain: Yup.string().required('Blockchain is required'),
  });

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  return (
    <div className={classess.mintNftContainer}>
      {loading && (
        <div className={classess.loading}>
          <Loading
            type={'bubbles'}
            color={'#2bc8c5'}
            height={'80px'}
            width={'80px'}
            containerHeight={'80vh'}
            containerWidth={'100%'}
            message={'Loading'}
          />
        </div>
      )}

      <Container>
        <div className={classess.mintNft}>
          <div className={classess.mintNftCol}>
            <div className={classess.mintNftHeader}>
              <h4 className={classess.heading}>Create Collection</h4>
            </div>
            <p className={classess.mintText}>
              Already have a collection?{' '}
              <a className={classess.mintLink} href="/mint-nft">
                Click here.
              </a>
              <br />
            </p>

            <p className={classess.mintText}>
              Ownerfy creates your collection as a dedicated smart contract where your NFTs can be stored on the
              blockchain allowing you to distinguish your brand and collect royalties.
            </p>

            {!user && !user?.uid && (
              <p className={classess.mintText}>
                1 contract and 10 NFT credits are included with your new account. Transfers and giving away NFTs by{' '}
                <a href="https://ownerfy.readme.io/reference/making-nfts-claimable-for-free" target="claim">
                  claim
                </a>{' '}
                also cost 1 credit each. You can <a href="/buy-credits">Buy more</a> anytime.{' '}
              </p>
            )}

            <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />

            <Formik
              initialValues={{
                collectionName: '',
              }}
              validationSchema={validationSchema}
              onSubmit={values => {
                onSubmit(values);
              }}
            >
              {({ errors, touched, values, setFieldValue }) => (
                <Form>
                  <div className={classess.inputWrapper}>
                    <Input
                      name="collectionName"
                      label="Collection Name"
                      touched={touched.collectionName}
                      error={errors.collectionName}
                      value={values.collectionName}
                      type="text"
                    />

                    {/* <Select
                      label="Blockchain"
                      name="selectBlockchain"
                      options={blockchain}
                      touched={touched.selectBlockchain}
                      error={errors.selectBlockchain}
                      value={values.selectBlockchain}
                      onChange={value => setFieldValue('selectBlockchain', value)}
                    /> */}

                    {/* <h4 className={classess.heading}>Default Attributes</h4>
                    <p className={classess.helperText}>
                      Leave blank if you are undecided. You can always change this later.
                    </p>

                    <div className={classess.attributesContainer}>
                      {attributes.map((item, index) => {
                        return (
                          <>
                            <div className={classess.attributes}>
                              <p className={classess.attributesLable}>Property</p>
                              <div className={classess.crossIcon} onClick={() => removeAttributes(item.id)}>
                                <img src={Cros} alt="not found" />
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <div>
                        <button type="button" className={classess.addBtn} onClick={() => addAttributes()}>
                          Add More
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <div className={classess.mintNftSection}>
                    <Button
                      disabled={!(user?.uid || isSignupLoginFlowValid || Number(userCredits) < 10)}
                      btnLabel="Create"
                      background
                    />
                    {user && user?.uid ? (
                      <div className={classess.mintFeeContainer}>
                        <span className={classess.minFee}>
                          10 <span className={classess.mintFeeLable}>Credits</span>
                        </span>
                        <span className={classess.mintFeeLable}>
                          You have {userCredits} credits. <br />
                          <a href="/buy-credits">Buy more</a>.
                        </span>
                      </div>
                    ) : (
                      <div className={classess.mintFeeContainer}>
                        <span className={classess.minFee}>Free plus 10 NFTs</span>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackBarClose} severity="error">
            The email is already in use.
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: actions.registerWithFirebase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
